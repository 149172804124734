.All_Graph_box {
	img {
		width: 100%;
	}
}
.kerala_Map {
	height: 500px;
	text-align: center;
	img {
		height: 100%;
	}
	iframe {
		width: 100%;
		height: 100%;
		margin-bottom: -7px;
	}
}
