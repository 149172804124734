.Create_ProjectBtn {
	border-radius: 30px;
	background: #bb8b63;
	color: #fff;
	&:hover {
		background: #8f6a4b;
		color: #fff;
	}
}
#ViewProject {
	.Create_ProjectBtn {
		position: absolute;
		right: 0px;
		top: 0px;
	}
	.section-title_inner{
	
	}
}
.ViewProjectCard {
	table.table {
		margin: 0px;
		tr {
			th {
				background: #bb8b63;
				color: #fff;
			}
		}
	}
}
.Action_btn {
	border-radius: 50%;
	background: #bb8b63;
	color: #fff;
	border: none;
	padding: 2px;
	height: 26px;
	width: 27px;
	font-size: 14px;
	&:hover {
		background: #8f6a4b;
	}
}
// .modal-backdrop.show {
//     opacity: .0!important;
// }

// .modal-backdrop {
//     position: absolute!important;
// }