.Create_ProjectBtn {
	border-radius: 30px;
	background: #bb8b63;
	color: #fff;
	&:hover {
		background: #8f6a4b;
		color: #fff;
	}
}
#ViewProject {
	.Create_ProjectBtn {
		position: absolute;
		right: 0px;
		top: 0px;
	}
}
.ViewProjectCard {
	table.table {
		margin: 0px;
		tr {
			th {
				background: #bb8b63;
				color: #fff;
			}
		}
	}
}
.Action_btn {
	border-radius: 50%;
	background: #bb8b63;
	color: #fff;
	border: none;
	padding: 2px;
	height: 26px;
	width: 27px;
	font-size: 14px;
	&:hover {
		background: #8f6a4b;
	}
}
.planted_ImgBox {
	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
		li {
			padding: 10px;
			img {
				width: 100%;
			}
		}
	}
}
.planted_ImgBox_view {
	img{
		height: 100%;
	}
	height: 160px;
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	button.Action_btn {
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
}
.Top_Per_Volunters {
	h6 {
		font-weight: 600;
		margin-bottom: 15px;
	}
}
.Top_Volunter_Details {
	padding: 10px;
}
.Top_Volunter_Details_inner {
	background: #bb8b63;
	padding: 15px;
	color: #fff;
	border-radius: 5px;
	position: relative;
	&::before {
		content: '';
		// background-image: url("/assets/img/leaf.png");
		height: 100%;
		width: 100%;
		position: absolute;
		right: 0px;
		top: 0px;
		background-size: cover;
		opacity: .4;
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}
	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
		margin-bottom: 10px;
		display: flex;
		position: relative;
		li {
			width: 100%;
			&:first-child {
				width: 90px;
			}
			&:last-child {
				padding: 15px 0px;
			}
		}
	}
	h6 {
		font-weight: 400;
		margin-bottom: 0px !important;
	}
	h5 {
		font-weight: 600;
		margin-bottom: 2px;
	}
	p {
		margin: 0px;
	}
	h4 {
		font-weight: 600;
		margin: 0px;
		position: relative;
	}
}
.Top_Volunter_img {
	width: 90px;
	height: 90px;
	overflow: hidden;
	border-radius: 50%;
	img {
		width: auto !important;
	}
}
#myTotalTab {
	border: none;
	margin-bottom: 18PX;
}
#myTotalTab.nav-tabs {
	.nav-link {
		font-weight: 500;
		border: none;
		&:focus {
			border-color: #fff;
		}
		&:hover {
			border-color: #fff;
		}
	}
	.nav-link.active {
		border: none;
		position: relative;
		&::before {
			content: '';
			background: #bb8b63;
			height: 4px;
			position: absolute;
			right: 15px;
			left: 15px;
			bottom: 0px;
		}
	}
}
.All_Graph_box {
	img {
		width: 100%;
	}
}
.kerala_Map {
	height: 500px;
	text-align: center;
	img {
		height: 100%;
	}
	iframe {
		width: 100%;
		height: 100%;
		margin-bottom: -7px;
	}
}
.Procurement_details_box {
	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
		display: flex;
		li {
			padding: 5px;
			&:first-child {
				width: 120px;
			}
		}
	}
}
.DistributionTotalbox {
	h4 {
		color: #bb8b63;
		margin: 0px;
		font-size: 18px;
		font-weight: 600;
	}
}
.My_Plantings_PhotoViewBox_head {
	position: relative;
}
.Select_Plantings_pinBox {
	position: absolute;
	top: 0px;
	right: 0px;
}
.My_Plantings_PhotoViewBox_Body {
	ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 9px;
			margin-bottom: 5px;
		}
	}
}
.My_Plantings_PhotoView {
	border-radius: 5px;
	width: 241px;
	height: 165px;
	overflow: hidden;
	position: relative;
	.carousel-item {
		height: 165px;
	}
}
.My_Plantings_PhotoView_count {
	position: absolute;
	top: 4px;
	left: 10px;
	color: #fff;
	z-index: 10;
}
.ShareWith_box {
	position: relative;
	background: #fff;
	border-radius: 30px;
	border: 1px solid #ddd;
	padding: 10px 15px;
	cursor: pointer;
	&:hover {
		background-color: #3f51b5;
		color: #fff;
		i.bi {
			color: #fff;
		}
	}
	i.bi {
		position: absolute;
		top: 0px;
		right: 7px;
		font-size: 27px;
		color: #3f51b5;
	}
}
.Btn_small {
    background-color: #bb8b63;
    color: #fff;
    padding: 12px 30px;
}
.modal_Bg{
	background-color: #bb8b63!important;
}
.close{
	background-color: transparent;
	border:none;
	font-size:larger;
	color:white
}
.modal_title{
	font-family: "Gotham Narrow Book", sans-serif !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 0;
    letter-spacing: 0.6px;
	color: #fff;
}
.cursor_point{
	cursor: pointer;
}

.Demo_margin{
	margin-left: 115px !important;
}
.Demo_recive_margin {
    margin-left: 88px !important;
}

.previewImage div{
	height: 100px;
	width: 40px;
}
.previewImage{
	display: flex;
	justify-content: center;
	margin-left: 20px;
	padding-bottom: 20px;
}

.procurement-list-grid {
	.ag-header-cell-label .ag-header-cell-text {
		white-space: normal !important;
	  }
}